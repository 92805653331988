import * as React from "react"
import Layout from "../../components/layout"
import Star from "../../components/star"
import Vid from "../../components/video"
import '../../style.css'


const Summary = ({location}) => {
    return (
    <Layout>
        <div>
            {location.state ?
                <h1 style={{marin:'25px 0'}}>{location.state.title}</h1>
                :
                null
            }
            <Star />
            {location.state ? <Vid videoLink={location.state.videoLink}/> : null }
            
            {location.state ?
                location.state.content.map( c => <p style={{textAlign:'start', margin:'25px 0'}}>{c}</p>)
                :
                null
            }
        </div>
    </Layout>
    )
}

export default Summary